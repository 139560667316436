<template>
	<div id="app" v-if="finishedLoadingComponents">
		<b-alert :show="isAlertShowing && !logged" :fade="true" dismissible :variant="alertColor">{{ $i18n.t(alertMessage)
		}}</b-alert>
		<transition name="app-fade" mode="out-in">
			<auth v-if="!logged" @login="login" />

			<div v-else>
				<header v-show="!isFullscreen">

					<logo-headNav @logout="logout">
						<template #logo>
							<div class="logoAndVersionContainer" @click="() => { isHoveringLogo = false }"
								@mouseover="() => { isHoveringLogo = true }" @mouseleave="() => { isHoveringLogo = false }">
								<div class="logoWrapper">
									<b-img v-if="!isHoveringLogo" class="tmhub" center
										:src="require('./assets/tmHub_white.png')" fluid />
									<b-img v-else center class="mtwLogo" :src="require('./assets/mtw_white.png')" fluid />
								</div>
								<strong>v1.0.74.14</strong>
							</div>
						</template>
					</logo-headNav>

					<menu-sidebar @hideSideBar="hideSideBar()" @showSideBar="showSideBar()" />
				</header>

				<transition name="loading">
					<div id="main">
						<lost-connection-overlay :failedConnectionWithAxios="failedConnectionWithAxios" />
						<main :style="mainStyle">
							<transition name="router-fade" mode="out-in">
								<router-view @toggleFullscreen="toggleFullscreen" />
							</transition>
						</main>
					</div>
				</transition>

				<b-alert :show="isAlertShowing" :fade="true" dismissible :variant="alertColor"
					class="position-fixed fixed-bottom m-0 rounded-0 api-error">
					{{ $i18n.t(alertMessage).replace("{target}", $i18n.t(errorOrigin)) }}
				</b-alert>

				<comment-alarm-modal />

			</div>


		</transition>
	</div>
</template>
  
<script>
/* eslint-disable */
import { loadRoutes } from './routes.js'
import Auth from './pages/Auth.vue'
import ToastContent from '../../Utils/components/shared/misc/Toast.vue'
import LostConnectionOverlay from '../../UtilsTelemetry/components/shared/LostConnectionOverlay/index.vue'

export default {
	components: {
		'Auth': Auth,
		LostConnectionOverlay
	},
	data() {
		return {

			isStatusNotificationLoading: false,
			isAlarmNotificationLoading: false,


			//Exibição 

			finishedLoadingComponents: false,
			isHoveringLogo: false,
			isFullscreen: false,

			isSidebarOpen: false,
			mainStyle: {
				'margin-left': '0',
			},

			// Alertas
			isAlertShowing: false,
			alertMessage: "Sucesso",
			alertColor: "success",
			errorOrigin: '',

			failedConnectionWithAxios: false,

			// Autenticação
			id: -1,
			refresh: true,
		}
	},

	methods: {

		//  Autenticação 

		//Remove a animação de carregar no index.html e carrega o template do App.vue
		loadApp() {
			var preRenderSpinner = document.getElementById('pre-render-app_spinner')
			if (preRenderSpinner) {
				preRenderSpinner.remove()
			}
			this.finishedLoadingComponents = true
		},

		loadUser() {
			var user = this.$session.get('user')
			return this.dashboardService
				.listByUserId(this.id)
				.then(dashboardList => {
					user.dashboards = dashboardList
					this.$session.set("user", user)
				})
		},

		async login(redirectToHomepage = true) {
			this.id = this.$session.get('logged_id');

			const url = this.$session.get("api_url")
			const servername = this.$apiController.GetServerName(url)

			const cookieExpireTime = this.$cookie.getExpireTime() / 60
			this.$cookie.setCookie("logged_id", this.id, cookieExpireTime)
			this.$cookie.setCookie("server_name", servername, cookieExpireTime)


			loadRoutes(this.$router);
			if (redirectToHomepage){
				this.loadUser()
				this.$router.push('/').catch(() => { });
			}
			this.refresh = !this.refresh;
			this.stopInterval()
			this.startInterval()
		},

		logout() {

			this.$session.set('logged_id', 0);
			this.clearCookies()

			this.refresh = !this.refresh;
			this.$toast.clear();
			this.stopInterval()
			this.hideSideBar()
		},

		clearCookies() {
			this.$cookie.eraseCookie("logged_id")
			this.$cookie.eraseCookie("server_name")
		},


		// Intervalos
		startInterval() {
			this.notificationsInterval = setInterval(this.reloadNotifications, 1500)
		},

		stopInterval() {
			clearInterval(this.reloadNotifications)
		},


		// Notificações 
		// Notificação -- Evento 

		showToast(message) {
			if (!this.$enableToasts.getIsToastsEnabled()) {
				this.$toast.clear();
				return
			}
			const router = this.$router
			const waitNextTick = async () => await this.$nextTick()

			const toast = message.isAlarm ?
				message.value == "Normal" ? this.$toast.success : this.$toast.error
				: message.value == "Online" ? this.$toast.success : this.$toast.warning

			const toastId = toast({
				component: ToastContent,
				props: { message: message, i18n: this.$i18n },
				listeners: {
					redirect: async (route) => {
						router.push('/dashboard').catch(() => { }); // se entrar no erro quer dizer que está no /dashboard
						await waitNextTick()
						router.push(route);
					},
					close: () => {
						this.$toast.dismiss(toastId)
					}
				},
			},
				{
					position: "bottom-left",
					timeout: 5000,
					closeOnClick: false,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: false,
					draggablePercent: 0.6,
					hideProgressBar: false,
					closeButton: false,
					showCloseButtonOnHover: true,
					icon: false,
					rtl: false,
				});
		},

		async reloadNotifications() {
			const userId = this.$session.get("logged_id")
			if (!this.isStatusNotificationLoading) {

				this.isStatusNotificationLoading = true

				this.statusService.getLatestByUserId(userId).then(res => {
					//console.log(res)
					Status.SetStatusResponse(res)
					this.isStatusNotificationLoading = false
				})
			}

			if (!this.isAlarmNotificationLoading) {

				this.isAlarmNotificationLoading = true

				this.alarmDataService.getLatestByUserId(userId).then(alarms => {
					for (let alarm of alarms) {
						let topic = `alarm/${alarm.id}`
						Alarm.UpdateAlarm(topic, alarm)
					}

					this.isAlarmNotificationLoading = false
				})

			}
			this.loadAlarmNotifications()
			this.loadStatusNotifications()
		},

		loadStatusNotifications() {
			// eslint-disable-next-line
			const statusMap = Status.GetStatusMap()
			statusMap.forEach(async (item) => {
				const topic = item.name
				const value = item.value
				if (!value.show || !value.statusNotification) return
				// eslint-disable-next-line
				Status.SetStatusShow(topic)
				const message = {
					isAlarm: false,
					value: value.value == '0' ? "Offline" : "Online",
					target: `${value.name} (${topic.split("/")[0]})`,
					time: value.datetime.split(" ")[1],
				}
				this.showToast(message)
			})

		},

		loadAlarmNotifications() {
			// eslint-disable-next-line
			const alarmMap = Alarm.GetAlarmMap()

			alarmMap.forEach(async item => {
				const { name, value } = item

				if (!value.show) return;
				// eslint-disable-next-line
				Alarm.SetAlarmShow(name)
				var listOfAlarmTypes = ["Normal", "Pre-Ativo", "Ativo"]
				var alarmType = listOfAlarmTypes[parseInt(value.value)]

				var affectedDashboards = []
				var affectedLayers = []

				this.alarmService
					.search(value.id)
					.then(res => {
						const message = {
							isAlarm: true,
							value: alarmType,
							id: parseInt(value.id),
							idDb: value.idDb,
							target: value.name,
							time: value.datetime.split(" ")[1],
							dashboards: affectedDashboards,
							layers: affectedLayers,
							showVideo: res.showVideo,
						}
						this.showToast(message)
					});


			})

		},


		// Notificação -- API
		showAlert() {
			this.isAlertShowing = true
			setTimeout(() => {
				this.isAlertShowing = false
			}, 10000)
		},


		// Exibição

		// Barra lateral
		showSideBar() {
			var margin = window.innerWidth <= 991 ? "0" : "15rem"
			this.isSidebarOpen = true
			this.mainStyle = {
				transition: '0.5s',
				'margin-left': margin,
			}
		},

		hideSideBar() {
			this.isSidebarOpen = false
			this.mainStyle = {
				transition: '0.5s',
				'margin-left': '0',
			}
		},

		// Tela cheia
		toggleFullscreen(state) {
			this.isFullscreen = state
			this.$root.$emit('bv::toggle::collapse', 'sidebar')
			this.hideSideBar()
		},


	},

	computed: {
		logged() {
			this.refresh;
			return this.$session.get('logged_id') > 0;
		},
	},

	async created() {
		this.$session.start();
		this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		this.$session.set('isMobile', this.isMobile)
		this.userService = new this.$userService();
		this.dashboardService = new this.$dashboardService();
		this.variableService = new this.$variableService();
		this.alarmService = new this.$alarmService();
		this.statusService = new this.$statusService();
		this.alarmDataService = new this.$alarmDataService();

		this.id = this.$session.get('logged_id');

		this.finishedLoadingComponents = false

		if (this.id > 0) {
			var api_url = this.$session.get("api_url")
			if (api_url != undefined) {
				this.$apiController.ChangeApiURL(api_url)
			}

			loadRoutes(this.$router);
			this.startInterval()
		}
		else {

			const cookieSessionUserId = this.$cookie.getCookie("logged_id")
			const serverName = this.$cookie.getCookie("server_name")
			const serverUrl = this.$apiController.GetServerURL(serverName)

			if (cookieSessionUserId != null && serverUrl != undefined) {

				this.$session.set("api_url", serverUrl)
				this.$apiController.ChangeApiURL(serverUrl)

				await this.userService
					.search(cookieSessionUserId)
					.then(async (user) => {
						if (user.enable && user.id > 0) {
							this.$session.set('logged_id', user.id);
							this.$session.set('user', user);
							this.$session.set('privilege', user.privilege);
							await this.login(false)
						} else {
							this.clearCookies()
						}
					}).catch(e => {
						console.error(e)
						this.clearCookies()
					})
			}

		}

		this.loadApp()

		this.$apiInterceptor.InterceptRequest(this)
		this.$apiInterceptor.InterceptResponse(this)
	},
}

</script>
  
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css";
@import "https://site-assets.fontawesome.com/releases/v6.2.1/css/all.css";
@import "./assets/scss/main.scss";
</style>
  
